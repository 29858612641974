import PROJECTS from "../Content/projects";

function useProjects(project_name = null) {
    if (project_name) {
        return PROJECTS.hasOwnProperty(project_name) ? PROJECTS[project_name] : null;
    } else {
        return PROJECTS
    }

}

export default useProjects;