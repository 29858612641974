import React from "react";
import styles from "./RecentProjectCube.module.css";
// import TextContent from "../TextContent/TextContent";
import {Link} from "react-router-dom";
import TextContent from "../TextContent/TextContent";

export default function RecentProjectCube({link, img_src, project_name}) {
    return (
        <div className={styles.project}>
            <Link to={link}>
                <div className={styles.image_top}>
                    <div className={styles.circles_container}>
                        <div className={styles.top_circle}/>
                        <div className={styles.top_circle}/>
                        <div className={styles.top_circle}/>
                    </div>
                </div>
                <div className={styles.project__img__box}>
                    <img className={styles.project__img}
                         src={img_src}
                         alt={`Project by Drorsoft at: ${link}`}/>

                    <div className={styles.project__hover}>

                        <div className={styles.circle}/>

                        <div className={styles.title}>View Project</div>
                    </div>
                </div>

                <div className={styles.project__info}>
                    <h3 className={styles.project__info__title}>
                        <TextContent page="recentProjects" project_name={project_name} property="title"/>
                    </h3>
                    <p className={styles.project__info__content}>
                        <TextContent page="recentProjects" project_name={project_name} property="content"/>
                    </p>
                </div>
            </Link>
        </div>
    );
}
