import React, {useState} from "react";
import Menu from "./Menu/Menu";
import styles from "./Fixed.module.css"
import CTA from "./CTA";

export default function Fixed() {
    const defaultWhite = getComputedStyle(document.documentElement).getPropertyValue('--colorDefaultWhite');
    const mainColor = getComputedStyle(document.documentElement).getPropertyValue('--colorMain');
    const [section, setSection] = useState('hero');

    const changeSection= () => {
        if (window.scrollY > window.innerHeight || window.location.pathname !== '/') {
            setSection('sections');
        } else {
            setSection('hero')
        }
    }

    window.addEventListener('scroll', changeSection)

    return (
        <div>
            <div className={styles.bar}>
                <Menu color={section === 'hero' ? defaultWhite : mainColor}/>
                <CTA section={section}/>
            </div>
        </div>
    );
}
