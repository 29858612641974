import React from "react";
import TextContent from "../TextContent/TextContent";
import styles from "./NumberedText.module.css";
import projectImg from "./../Images/what_we_offer_project.jpg"

export default function NumberedText({ page, item_number }) {
    return (
        <div className={styles.NumberedText}>
            <div className={styles.number}>
                <TextContent page={page} property="number" section={item_number}/>
                <img src={projectImg}
                     alt={'what we offer - project'}
                     className={styles.project_img}
                />
            </div>
            <div className={styles.line}/>
            <div className={styles.text_wrapper}>
                <div className={styles.text_title}>
                    <h2>
                    <TextContent page={page} property="title" section={item_number}/>
                    </h2>
                </div>
                <div className={styles.content}>
                    <TextContent page={page} property="description" section={item_number}/>
                </div>
            </div>
        </div>
    );
}
