import "./Menu.css";
import React, {useState} from "react";
import {HashLink} from "react-router-hash-link";
import {useMediaQuery} from 'react-responsive'

export default function Menu(props) {
    const [isActive, setIsActive] = useState(false);
    const defaultWhite = 'var(--colorDefaultWhite)'

    const backgroundColor = isActive ? defaultWhite : props.color
    const menuActiveClass = isActive ? "active" : "";
    if (useMediaQuery({query: '(max-width: 900px)'}) && isActive) {
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflow = "visible"
    }
    const menuBurgerLevelStyle = {
        backgroundColor: backgroundColor,
    }

    return (
        <div>
            <div id="menuBurger"
                 className={menuActiveClass}
                 onClick={() => setIsActive(!isActive)}>
                <div id="m1" className="menuBurgerLevel" style={menuBurgerLevelStyle}/>
                <div id="m2" className="menuBurgerLevel" style={menuBurgerLevelStyle}/>
                <div id="m3" className="menuBurgerLevel" style={menuBurgerLevelStyle}/>
            </div>

            <div id="menuBox" className={menuActiveClass}>
                <div className="menuItemsContainer">
                    <div id="menuAnchors" onClick={() => setIsActive(!isActive)}>
                        <HashLink to="/#WhoWeAre">
                            <h1 className="menuAnchor">WHO WE ARE</h1>
                        </HashLink>
                        <HashLink to="/#OurTeam">
                            <h1 className="menuAnchor">OUR TEAM</h1>
                        </HashLink>
                        <HashLink to="/#WhatWeOffer">
                            <h1 className="menuAnchor">WHAT WE OFFER</h1>
                        </HashLink>
                        <HashLink to="/#recentProjects">
                            <h1 className="menuAnchor">RECENT PROJECTS</h1>
                        </HashLink>
                        <HashLink to="/#Contact">
                            <h1 className="menuAnchor">CONTACT</h1>
                        </HashLink>
                    </div>

                    <div className="comInfo">
                        <ul className="comInfo__list">
                            <li>CBHC Computing</li>
                            <li>B.N. Number 515241479</li>
                            <li>
                                <a href="https://goo.gl/maps/Z6GcQQJQjaHPx1So6" target="_blank" rel="noreferrer">
                                    Hagana Road 34, Tel Aviv-Yafo 6772137
                                </a>
                            </li>
                            <li>
                                <a href="mailto: contact@drorsoft.com">contact@drorsoft.com</a>
                            </li>
                            <li>
                                <a href="tel:+972546735034">054-6735034</a>
                            </li>
                        </ul>
                    </div>

                    <div className="menuSocial">
                        <a href="https://www.facebook.com/drorsoft"
                           target="_blank"
                           rel="noopener noreferrer">
                            <h3>Facebook</h3>
                        </a>
                        <h3>&nbsp;&nbsp;|&nbsp;&nbsp;</h3>
                        <a href="https://linkedin.com/company/drorsoft"
                           target="_blank"
                           rel="noopener noreferrer">
                            <h3>Linkedin</h3>
                        </a>
                        <h3>&nbsp;&nbsp;|&nbsp;&nbsp;</h3>
                        <a href="https://twitter.com/drorsoft"
                           target="_blank"
                           rel="noopener noreferrer">
                            <h3>Twitter</h3>
                        </a>


                    </div>
                </div>
            </div>
        </div>
    );
}
