import React from "react";
import styles from "./dlogo.module.css";
import { ReactComponent as DLogo } from "../Images/dlogo.svg";
import { HashLink } from "react-router-hash-link";

function DrorsoftSmallLogo() {
  return (
    <HashLink to="/" className={styles.dLogo}>
      <DLogo className={styles.dLogoSize} />
    </HashLink>
  );
}

export default DrorsoftSmallLogo;
