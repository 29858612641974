import amit from "../Images/ourTeam/AmitYizraeli_450.JPG";
// import yoav from "../Images/ourTeam/YoavSagi_450.jpeg";
import ori from "../Images/ourTeam/OryRosin.jpeg";
import dany from "../Images/ourTeam/DanyEfrati_450.png";
import alonp from "../Images/ourTeam/AlonPalgy_450.jpeg";
import teamers from "../Images/ourTeam/Teamers_450.jpg";

const teamMembers = [
	{
		name: "Dani Efrati",
		role: "Algorithmic development",
		skill: "info-systems",
		description:
			"Full stack developer, passionate about solving algorithmic challenges.",
		image: dany,
		email: "email@mail.com",
	},
	{
		name: "Amit Yizraeli",
		role: "Head of sales",
		phone: "+972546735034",
		skill: "cloud",
		description:
			"With extensive experience in leading product and project teams, Amit will help you identify the features your project really needs, helping you navigate away from feature creep. He also dabbles in coding when needed.",
		image: amit,
		email: "email@mail.com",
	},
	// {
	//     name: "Yoav Sagi",
	//     role: "Frontend lead",
	//     phone: "+972549732436",
	//     skill: "web",
	//     description:
	//         "Passionate about anything frontend, Yoav has led various types of complex projects. He is also a talented musician.",
	//     image: yoav,
	//     email: "email@mail.com"
	// },
	{
		name: "Ory Rosin",
		role: "Frontend lead",
		description:
			"Passionate about anything frontend, Ory has led various types of complex projects. He also loves to play improv theatre and go\n" +
			"wind-surfing.",
		image: ori,
	},
	{
		name: "Alon Palgy",
		role: "Backend lead",
		skill: "info-systems",
		description:
			"With many years of industry experience, Alon is a full stack developer and a true problem solver, ready to face difficult problems, one at a time.",
		image: alonp,
		email: "email@mail.com",
	},
];

const teamCard = {
	title: "The rest of the team",
	role: "Backend, Frontend, Product managers",
	description:
		"Our special sauce and added value. Our team of top notch developers  with a wide variety of skills succeeds at even the most complex projects at the highest standards and on time. ",
	image: teamers,
};

export { teamMembers, teamCard };
