import React from "react";
import RecentProjectCube from "../../Partials/RecentProjectCube";
import styles from "./RecentProjects.module.css";
import TextContent from "../../TextContent/TextContent";
import useProjects from "../../hooks/useProjects";
import {Link} from "react-router-dom";

export default function RecentProjects({projectsAmount}) {
    const recentProjects = useProjects()
    const projectNameList = Object.keys(recentProjects)
    const projectsAmountToShow = projectsAmount ? projectsAmount : projectNameList.length

    return (
        <div id="recentProjects" className={styles.recentProjects}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>
                        <TextContent page={projectsAmount ? "recentProjects" : "projects"} property="title"/>
                    </h1>
                    {projectsAmount && <div className={styles.header_link}>
                        <Link to={'/projects'}>
                            see more
                        </Link>
                    </div>}
                </div>

                <div className={styles.container__grid}>
                    {recentProjects && projectNameList.slice(0, projectsAmountToShow).map((recentProject) => {
                        return <div className={styles.project_cube} key={recentProject}>
                            <RecentProjectCube link={`/project/${recentProject}`}
                                               img_src={recentProjects[recentProject].homepage_image}
                                               project_name={recentProject}/>
                        </div>
                    })}
                </div>

                {projectsAmount && <div className={styles.bottom_link}>
                    <Link to={'/projects'}>
                    see more
                    </Link>
                </div>}
            </div>
        </div>
    );
}
