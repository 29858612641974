const landingPages = [
  {
    id: 1,
    techName: "test",
    title: "ut quos sapiente quos sapiente!",
    description:
      "Every organization today needs an interactive internet presence. We deliver full turn-key solutions including design, specification, requirements, setup, analytics and even cloud infrastructure specifically tailored for you and your organization.",
    quotes: [
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      {
        text: "You will never be happy if you continue to search for what happiness consists of. You will never live if you are looking for the meaning of life.",
        name: "Albert Camus",
      },
    ],
    imgsDesktop: [
      "https://drorsoft.com/static/media/whoweare2.873a3661.jpg",
      "https://drorsoft.com/static/media/keshet_main.dbabf3de.jpeg",
    ],
    imgsMobile: [
      "https://drorsoft.com/static/media/keshet2.71dd5104.jpeg",
      "https://drorsoft.com/static/media/keshet1.212cf118.jpeg",
    ],
  },
  {
    id: 2,
    techName: "web",
    title: "WEB APPS AND WEBSITES",
    description:
      "Every organization today needs an interactive internet presence. We deliver full turn-key solutions including design, specification, requirements, setup, analytics and even cloud infrastructure specifically tailored for you and your organization.",
    quotes: [
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      { text: "Hey man whats up?", name: "Sir Mix-A-Lot" },
      {
        text: "You will never be happy if you continue to search for what happiness consists of. You will never live if you are looking for the meaning of life.",
        name: "Albert Camus",
      },
    ],
    imgsDesktop: [
      "https://drorsoft.com/static/media/whoweare2.873a3661.jpg",
      "https://drorsoft.com/static/media/keshet_main.dbabf3de.jpeg",
    ],
    imgsMobile: [
      "https://drorsoft.com/static/media/keshet2.71dd5104.jpeg",
      "https://drorsoft.com/static/media/keshet1.212cf118.jpeg",
    ],
  },
];

export { landingPages };
