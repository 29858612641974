import React from 'react';
import {Toaster} from "react-hot-toast";

function Toast() {
    const toastOptions = {
        style: {
            background: "var(--colorMain)",
            color: "var(--colorDefaultWhite)",
            padding: '1em',
        },
        success: {
            style: {
                background: "green",
            },
            iconTheme: {
                primary: 'var(--colorDefaultWhite)',
                secondary: 'var(--colorMain)',
            },
        },
        error: {
            style: {
                background: "var(--colorDefaultWhite)",
                color: "black",
            },
            iconTheme: {
                primary: 'var(--brightRed)',
                secondary: 'var(--colorDefaultWhite)',
            },
        },
    }

    return (
        <Toaster toastOptions={toastOptions}/>
    );
}

export default Toast;