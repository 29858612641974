import React from 'react';
import styles from "./Project.module.css"
import TextContent from "../../TextContent/TextContent";
import {useParams} from "react-router-dom";
import useProjects from "../../hooks/useProjects";
import ImageGallery from 'react-image-gallery';
import BackButton from "./BackButton";

function Project() {
    const {project_name} = useParams();
    const project = useProjects(project_name)
    const desktopImages = [
        ...project.other_images.map(image => {
            return ({
                original: image,
            })
        }),
        {
            original: project.homepage_image,
        },
    ]
    const mobileImages = [
        ...project.mobile_images.map(image => {
            return ({
                original: image,
            })
        })
    ]

    if (project) {
        return (
            <div className={styles.container}>
                <BackButton/>
                <div className={styles.info}>
                    <section className={styles.info__heading}>
                        <h1><TextContent page="recentProjects" project_name={project_name} property="title"/></h1>
                        <p><TextContent page="recentProjects" project_name={project_name} property="content"/></p>
                    </section>
                    {project.customers && (<section>
                        <h3><TextContent page="recentProjects" property="customersTitle"/></h3>
                        <p><TextContent page="recentProjects" project_name={project_name} property="customers"/></p>
                    </section>)}
                    {project.need && (<section>
                            <h3><TextContent page="recentProjects" property="needTitle"/></h3>
                            <p><TextContent page="recentProjects" project_name={project_name} property="need"/></p>
                        </section>
                    )}
                    {project.solution && (<section>
                            <h3><TextContent page="recentProjects" property="solutionTitle"/></h3>
                            <p><TextContent page="recentProjects" project_name={project_name} property="solution"/></p>
                        </section>
                    )}
                    {project.live_link && (<section className={styles.project_link}>
                            <a href={project.live_link}
                               target="_blank"
                               rel="noreferrer"
                               className="button">
                                <TextContent page="recentProjects" property="buttonTitle"/>
                            </a>
                        </section>
                    )}
                </div>

                <div className={styles.gallery_side}>
                    <div className={styles.images}>
                        <div className={styles.image_top}/>
                        <div className={styles.image_gallery_container}>
                            <ImageGallery
                                items={desktopImages}
                                showFullscreenButton={false}
                                showPlayButton={true}
                                autoPlay={true}
                                showBullets={true}
                                bulletClass={styles.images_gallery_bullet}
                                bulletActiveClass={styles.images_gallery_bullet_active}
                            />
                        </div>
                    </div>

                    <div className={`${styles.mobile_images} ${styles.images}`}>
                        <div className={styles.image_top}/>
                        <div className={styles.image_gallery_container}>
                            <ImageGallery
                                items={mobileImages}
                                showFullscreenButton={false}
                                showPlayButton={true}
                                autoPlay={true}
                                showBullets={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <h1>Project Not Found</h1>
            </div>

        )
    }
}

export default Project;