import React from "react";
import styles from "./Person.module.css"
import selectedOption from "../../../snapshots/selectedOption";
import {useSnapshot} from "valtio";
import TextContent from "../../../TextContent/TextContent";

export default function Person({teamMember, index}) {
    useSnapshot(selectedOption)


    return (
        <div className={index % 2 === 1 ? styles.person__odd : styles.person}>
            <div className={styles.head}>
                <img src={teamMember.image}
                     className={styles.heading__img}
                     alt="A Person"/>
                <div className={styles.heading}>
                    <div className={styles.heading__bar}/>
                    <div className={styles.heading__text}>
                        <h1 className={styles.heading__name}>{teamMember.name}</h1>
                        <span className={styles.heading__role}> {teamMember.role}</span>
                        <div className={styles.hidden__wide_screen}>
                            <div className={styles.line}/>
                            <div className={styles.email}>
                                <a href="#Contact"><TextContent page={"ourTeam"} property={"contact-form-link"}/></a>
                            </div>
                        </div>

                    </div>
                    <br/>
                </div>
            </div>
            <div className={styles.description}>{teamMember.description}
            </div>
            <div className={styles.hidden_mobile}>
                <div className={styles.line}/>
                <div className={styles.email}>
                    <a href="#Contact"><TextContent page={"ourTeam"} property={"contact-form-link"}/></a>
                </div>
            </div>

        </div>
    );
}
