import React from "react";
import TextContent from "../../TextContent/TextContent";
import { useMediaQuery } from 'react-responsive'
import "./Hero.css";
import LinesWithCircles from "../../Partials/LinesWithCircles";

export default function Hero() {
    const isBiggerThen900W = useMediaQuery({
        query: '(min-width: 900px)'
    })

    const line_modifier = isBiggerThen900W ? 160 : 250;

    return (
        <div className="hero hero-background" >
            <LinesWithCircles className="hero__white_lines" line_modifier={line_modifier} rotationDegree={90}/>
            <h1 id="hero-slogan">
                <TextContent page="hero" property="title"/>
            </h1>
        </div>
    );
}
