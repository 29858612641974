import React from 'react';
import styles from "./wrapper.module.css"

function Warpper(props) {
    return (
        <div className={styles.wrapper}>
            {
            props.children
        }</div>
    );
}

export default Warpper;