import React from "react";
import NumberedText from "../../Partials/NumberedText";
import styles from "./WhatWeOffer.module.css";
import WhatWeOfferImg from "../../Images/WhatWeOffer-2.jpg";
import TextContent from "../../TextContent/TextContent";

export default function WhatWeOffer() {
    const page_name = "whatWeOffer";
    const offersList = [
        "web", "info-systems", "automation", "cloud",  "apps", "bots"
    ]


    return (
        <div id="WhatWeOffer" className={styles.WhatWeOffer}>
            <div className={styles.blue_cube}></div>

            <div className={styles.header_container}>
                <div className={styles.img_side}>
                    <img className={styles.img}
                         src={WhatWeOfferImg}
                         alt="What We Offer"/>
                </div>

                <div className={styles.text_cube}>
                    <h1>
                        <TextContent page="whatWeOffer" property="title" />
                    </h1>
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.left_cube}></div>
                <div className={styles.offers__container}>
                    {offersList.map((item) =>
                        <div className={styles.offer__container}>
                            <div className={styles.numbered_offer}>
                                <NumberedText page={page_name} item_number={item}/>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.right_cube}></div>
            </div>
        </div>
    );
}
