import React from "react";
import Person from "./person/Person";
import styles from "./OurTeam.module.css";
import {teamMembers} from "../../Content/teamMembers";
import LinesWithCircles from "../../Partials/LinesWithCircles";

export default function OurTeam() {
    return (
        <div className={styles.ourTeam} id="OurTeam">
            <div className={styles.container}>
                {teamMembers && teamMembers.length > 0 && teamMembers.map((teamMember, index) =>
                    <div className={styles.person} key={teamMember.name}>
                        {index + 1 === teamMembers.length &&
                            <LinesWithCircles className={styles.lines} rotationDegree={270} line_modifier={150}/>}
                        <Person teamMember={teamMember} index={index}/>
                    </div>
                )}
            </div>
        </div>
    );
}
