import React from "react";
import styles from "./CTA.module.css"
import clsx from "clsx";

export default function CTA(props) {
    const color = props.color

    return (
        <div className={clsx(styles.cta, props.section === 'hero' ? styles.transparent : styles.background)}
             style={{
                 color: color
             }}>
            <div className={styles.links}>
                <a href="#Contact">CONTACT</a><span>|</span><a href="#recentProjects"> PORTFOLIO</a>
            </div>
        </div>
    );
}
